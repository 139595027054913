import { defineStore } from "pinia";
import { AdAccount, AdAccountResponse } from "~/types/AdAccount";
import { CustomMetricRule } from "~/types/Metrics";
import { Provider } from "~/types/shared";

export const AD_ACCOUNT_STORE = "ad-account-store";
const BATCH_SIZE = 100;

export const useAdAccountStore = defineStore({
  id: AD_ACCOUNT_STORE,
  state: () => {
    return {
      adAccounts: [],
    } as {
      adAccounts: Array<AdAccount>;
    };
  },
  actions: {
    async listAdAccounts() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { adAccounts: Array<AdAccountResponse> };
      }>("adaccount");
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedAdAccounts } = useAdAccounts();
        this.adAccounts = getMappedAdAccounts(data.value.data.adAccounts);
      }
      return this.adAccounts;
    },

    async updateAdAccounts(
      adAccounts: Array<{
        adAccountId: number;
        clientId?: number | null | undefined;
        isMapped?: boolean | undefined | null;
        loadLifetimeData?: boolean | undefined | null;
      }>,
    ) {
      // Do an optimistic update i.e. update internal state first even, if API operation is not successful
      // This helps flickering of UI during API call
      for (const adAccount of adAccounts) {
        const index = this.adAccounts.findIndex(
          (a) => a.id === adAccount.adAccountId,
        );
        if (index !== -1) {
          this.adAccounts[index] = {
            ...this.adAccounts[index],
            deleted: false,
            clientId:
              typeof adAccount.clientId === "number" ||
              adAccount.clientId === null
                ? adAccount.clientId
                : this.adAccounts[index].clientId,
          };
        }
      }
      // Update in batches
      for (let i = 0; i < adAccounts.length; i += BATCH_SIZE) {
        const { error } = await useDatAdsApiFetch("adaccount", {
          method: "PATCH",
          body: adAccounts.slice(i, i + BATCH_SIZE),
        });
        if (error.value) {
          const errorMessage = useErrorHandler(error.value);
          return errorMessage;
        }
      }
      return null;
    },

    async deleteAdAccount(adAccountId: number) {
      const { error } = await useDatAdsApiFetch(`adaccount/${adAccountId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.adAccounts = this.adAccounts.filter(
        (adAccount) => adAccount.id !== adAccountId,
      );
      return null;
    },

    async deleteAdAccounts(adAccountIds: number[]) {
      // Update in batches
      for (let i = 0; i < adAccountIds.length; i += BATCH_SIZE) {
        const { error } = await useDatAdsApiFetch("adaccount/bulk-delete", {
          method: "POST",
          body: adAccountIds.slice(i, i + BATCH_SIZE).map((adAccountId) => ({
            adAccountId,
          })),
        });
        if (error.value) {
          const errorMessage = useErrorHandler(error.value);
          return errorMessage;
        }
      }
      this.adAccounts = this.adAccounts.filter(
        (adAccount) => !adAccountIds.includes(adAccount.id),
      );
      return null;
    },
  },
  getters: {
    getAdAccountById:
      (state) =>
      (adAccountId: number): AdAccount | null => {
        const acc = state.adAccounts.find(
          (adAccount) => adAccount.id === adAccountId,
        );
        return acc || null;
      },

    notDeletedAdAccounts: (state) =>
      state.adAccounts.filter((adAccount) => !adAccount.deleted),

    deletedAdAccounts: (state) =>
      state.adAccounts.filter((adAccount) => adAccount.deleted),

    currency: (state) => (adAccountId: number) => {
      const acc = state.adAccounts.find(
        (adAccount) => adAccount.id === adAccountId,
      );
      return acc?.currency || "EUR";
    },

    customConversionNames:
      (state) =>
      (dto: {
        clientId: number;
        providers: Provider[];
      }): Record<string, string> => {
        return state.adAccounts
          .filter(
            (adAccount) =>
              adAccount.clientId === dto.clientId &&
              dto.providers.includes(adAccount.provider),
          )
          .map((adAccount) => adAccount.customConversionNames)
          .reduce((acc, customConversionNames) => {
            return { ...acc, ...customConversionNames };
          }, {});
      },

    customMetricRules:
      (state) =>
      (dto: {
        clientId: number;
        providers: Provider[];
      }): CustomMetricRule[] => {
        return state.adAccounts
          .filter(
            (adAccount) =>
              adAccount.clientId === dto.clientId &&
              dto.providers.includes(adAccount.provider),
          )
          .flatMap((adAccount) => adAccount.customMetricRules);
      },

    providerOfCustomConversion: (state) => (customConversionId: string) => {
      const adAccount = state.adAccounts.find((adAccount) =>
        Object.keys(adAccount.customConversionNames).includes(
          customConversionId,
        ),
      );
      return adAccount != null ? adAccount.provider : null;
    },

    providerOfCustomMetric: (state) => (customMetricId: string) => {
      const adAccount = state.adAccounts.find((adAccount) =>
        adAccount.customMetricRules.some(
          (rule) => rule.metricName === customMetricId,
        ),
      );
      return adAccount != null ? adAccount.provider : null;
    },

    isCustomMetric: (state) => (metricId: string) => {
      return state.adAccounts.some((adAccount) =>
        adAccount.customMetricRules.some(
          (rule) => rule.metricName === metricId,
        ),
      );
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdAccountStore, import.meta.hot));
}
